import { useState, useEffect } from "react";
import ReturnOrderTable from "./ReturnOrderTable";
import { getCall } from "../../../Api/axios";
import { useTheme } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";

export default function Returns() {
  const columns = [
  {
      id: "orderId",
      label: "Order Id",
      minWidth: 120,
      align: "center",
  },
  {
      id: "item_name",
      label: "Product Name",
      minWidth: 120,
      align: "center",
  },
  {
      id: "qty",
      label: "Item Returned",
      minWidth: 120,
      align: "center",
  },
  {
    id:"item_code",
    label:"Product Number",
    minWidth:120,
    align:"center",
  },
  {
    id: "state",
    label: "Status",
    minWidth: 120,
    align: "center",
  },
  {
    id: "createdAt",
    label: "Created On",
    minWidth: 180,
    align: "center",
  },
  {
  id: "updatedAt",
  label: "Last Modified On",
  minWidth: 180,
  align: "center",
},
  {
    id: "reason",
    label: "Reason",
    minWidth: 250,
    align: "center",
  },
];

  const theme = useTheme();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [returnsColumns, setReturnsColumns] = useState(columns);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem("returnstablepage");
    return savedPage !== null ? parseInt(savedPage, 10) : 0
  });
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = sessionStorage.getItem("returnstablerowsperpage");
    return savedRowsPerPage !== null ? parseInt(savedRowsPerPage, 10) : 25
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);

  const getReturnOrders = (limit, offset) => {
    let url ="";
    if (user && user?.role?.name == "Organization Admin") {
      url = `/api/v1/orders/return/request?limit=${limit}&offset=${offset}`;
      getCall(url)
      .then((resp) => {
        console.log("Here is the resp.data");
        console.dir(resp.data,{depth:null});
        setData(resp.data);
        setTotalRecords(resp.count);
      })
      .catch((error) => {
        console.log(error.response);
      })
    } else {
      // Adding provider name column for the super admin
      setReturnsColumns([{
        id: "provider_name",
        label: "Provider Store Name",
        minWidth: 170,
        align: "center",
    }, ...columns]);
      url = `/api/v1/orders/return/request/admin?limit=${limit}&offset=${offset}`;
      getCall(url)
      .then((resp) => {
        console.log("Here is the resp.data");
        console.dir(resp.data,{depth:null});
        setData(resp.data);
        setTotalRecords(resp.count);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        setLoading(false);
      })
    }
  };

  useEffect(() => {
    setLoading(true);
    getReturnOrders(rowsPerPage, page);
  }, [page, rowsPerPage]);

  const handleRefresh = () => {
    getReturnOrders(rowsPerPage, page);
  };

  const handlePageChange = (val) => {
    setPage(val);
    setLoading(true);
    sessionStorage.setItem("returnstablepage", val);
  }

  const handleRowsPerPageChange = (val) => {
    setRowsPerPage(val);
    sessionStorage.setItem("returnstablerowsperpage", val);
  }

  const loadingView = (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      backgroundColor: "#fff",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <>
      {loading ? loadingView : (
        <>
          <div className="container mx-auto my-8 px-2 md:px-8">
            <div className="mb-4 flex flex-row justify-between items-center">
              <label style={{color: theme.palette.text.primary}} className="font-semibold text-2xl">Returns</label>
            </div>
            <ReturnOrderTable
              columns={returnsColumns}
              data={data}
              totalRecords={totalRecords}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
              handleRefresh={handleRefresh}
            />
          </div>
        </>)}
      </>
  );
}
